"use client";

import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Alert } from "components/Alert";
import useCart from "hooks/useCart";
import useCurrentUserWaitlistEntries from "hooks/useCurrentUserWaitlistEntries";
import Link from "next/link";
import { usePathname } from "next/navigation";
import type { ComponentProps } from "react";
import { useEffect, useState } from "react";
import { WaitlistEntryStatus } from "types/model/waitlistEntry";

/**
 * Alert to notify the user that a spot has opened up for them on the Waitlist.
 */
export const ReadyWaitlistEntriesMessage = (
  props: Omit<ComponentProps<typeof Alert>, "children">
) => {
  const currentPath = usePathname();

  const { data: waitlistData } = useCurrentUserWaitlistEntries(0, 1000);
  const { data: cartData } = useCart();
  const [
    hasItemsFromWaitlistEntriesInCart,
    setHasItemsFromWaitlistEntriesInCart
  ] = useState(false);

  useEffect(() => {
    if (cartData) {
      const hasItemsFromWaitlistEntriesInCart =
        cartData.activityGroupItems.some(activityGroupItem =>
          activityGroupItem.ticketItems.some(ticketItem =>
            ticketItem.lineItems.some(lineItem => !!lineItem.waitlistEntryId)
          )
        );
      setHasItemsFromWaitlistEntriesInCart(hasItemsFromWaitlistEntriesInCart);
    }
  }, [cartData]);

  const readyEntries = (waitlistData?.data || []).filter(
    waitlistEntry => waitlistEntry.status === WaitlistEntryStatus.Ready
  );

  if (
    ["/account/waitlits", "/cart", "/checkout"].some(url =>
      currentPath?.startsWith(url)
    ) ||
    !readyEntries?.length ||
    hasItemsFromWaitlistEntriesInCart
  ) {
    return null;
  }

  const isMultipleReadyEntries = readyEntries?.length > 1;
  const isMutlipleSessions =
    [...new Set(readyEntries.map(entry => entry.activity))].length > 1;

  return (
    <Alert
      variant="blue"
      {...props}
      icon={<InformationCircleIcon className="h-5 w-5 text-blue-400" />}
      textClassName="font-normal"
    >
      {isMultipleReadyEntries
        ? `${readyEntries.length} spots have`
        : "A spot has"}{" "}
      become available on{" "}
      {isMutlipleSessions ? "Waitlists you have" : "a Waitlist you"} joined!
      View details in your{" "}
      <Link
        href="/account/waitlists"
        className="cursor-pointer underline hover:text-blue-500"
      >
        My account
      </Link>{" "}
      area.
    </Alert>
  );
};
